@font-face {
    font-family: PingFang;
    src: url('./static/font/PingFang Regular.ttf');
}

@font-face {
    font-family: Douyin Sans;
    src: url('./static/font/DouyinSansBold.otf');
}

.App {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    font-family: 'PingFang';
    color: #333333;
    /* iOS Safari */
    -webkit-touch-callout: none;
    /* Chrome/Safari/Opera */
    -webkit-user-select: none;
    /* Firefox */
    -moz-user-select: none;
    /* Internet Explorer/Edge */
    -ms-user-select: none;
    /* Non-prefixed version, currently supported by most modern browsers */
    user-select: none;

    .no-ios {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}